import React from 'react'
import 'typeface-inter'
import './styles/bootstrap.scss'
import './styles/base.scss'
import './styles/app.scss'
import './styles/responsive.scss'
import './styles.scss'

function App() {
  return (
    <div className='App' id='up'>
      <div className='flex between v-center header'>
        <div className='logo'>
          <a href='#up'>
            <img src='/assets/logo.svg' />
          </a>
        </div>
        <div className='flex around v-center nav'>
          <div className='nav__item'>
            <a href='#pros'>Ventajas</a>
          </div>
          <div className='nav__item'>
            <a href='#services'>Servicios</a>
          </div>
          <div className='nav__item'>
            <a href='#us'>Nosotros</a>
          </div>
          <div className='nav__item'>
            <a href='#footer'>Contacto</a>
          </div>
          <a href='https://gestion.car2door.es/'>
            <button className='c-button'>Clientes</button>
          </a>
        </div>
      </div>
      <div className='flex h-center first-section'>
        <div className='flex general-wrapper'>
          <div className='title'>Plataforma tecnológica de movilidad</div>
          <h1>Recogida y entrega de vehículos personalizada</h1>
          <div className='text'>Software de gestión de traslados, adaptado a tus necesidades.</div>
        </div>
      </div>
      <div className='flex h-center calendar'>
        <div className='flex general-wrapper'>
          <div className='wrapper-image'>
            <img src='/assets/calendar.png' />
          </div>
        </div>{' '}
      </div>
      <div className='flex h-center second-section' id='pros'>
        <div className='flex h-center general-wrapper'>
          <div className='subtitle'>Ventajas</div>
          <h2 className='center'>Tu herramienta para la movilidad, control de servicios y gestión de clientes</h2>
          <div className='flex cols-3 second-section__grid'>
            <div className='grid__item'>
              <div className='wrapper-icon'>
                <img src='/assets/remote-control.svg' />
              </div>
              <div className='item__title'>Gestión remota</div>
              <div className='item__text'>
                Gestiona los traslados de vehículos sin desplazarte por mantenimientos, reparaciones, entregas o ITV.
              </div>
            </div>
            <div className='grid__item'>
              <div className='wrapper-icon'>
                <img src='/assets/calendar.svg' />
              </div>
              <div className='item__title'>Agenda y calendario</div>
              <div className='item__text'>
                Planifica recogidas y entregas, facilitando el control de los servicios, y de los empleados.
              </div>
            </div>
            <div className='grid__item'>
              <div className='wrapper-icon'>
                <img src='/assets/sustitution-car.svg' />
              </div>
              <div className='item__title'>Vehículo de sustitución</div>
              <div className='item__text'>
                Gestiona vehículos de sustitución con una entrega personalizada hasta el domicilio del cliente.
              </div>
            </div>
            <div className='grid__item'>
              <div className='wrapper-icon'>
                <img src='/assets/fast-car.svg' />
              </div>
              <div className='item__title'>Respuesta rápida</div>
              <div className='item__text'>Opera más rápido para realizar los servicios en el menor tiempo posible.</div>
            </div>
            <div className='grid__item'>
              <div className='wrapper-icon'>
                <img src='/assets/types-car.svg' />
              </div>
              <div className='item__title'>Tipos de transporte</div>
              <div className='item__text'>
                Conductores-grúa, urgente, 24 h, 48 h, personalizado, repatriación de vehículos, ITV, V. de sustitución,
                y mucho más.
              </div>
            </div>
            <div className='grid__item'>
              <div className='wrapper-icon'>
                <img src='/assets/follow-car.svg' />
              </div>
              <div className='item__title'>Control y seguimiento</div>
              <div className='item__text'>
                Información en tiempo real del estado de cada servicio y un control global de los movimientos.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex h-center third-section' id='services'>
        <div className='general-wrapper'>
          <div className='subtitle'>Servicios</div>
          <h2>Todo lo que necesites</h2>
          <div className='cols-4 third-section__grid'>
            <div className='grid__item blue'>
              Aplicación para vehículos que necesiten mantenimiento, ITV, lavados, etc
            </div>
            <div className='grid__item'>Chofer-App. Recepción de los servicios y tramitación del mismo</div>
            <div className='grid__item blue'>Servicio en todo el territorio nacional</div>
            <div className='grid__item'>Movilidad para compañías, talleres, flotas o particulares</div>
            <div className='grid__item'>Comunicación fluida y coordinación con proveedores y clientes</div>
            <div className='grid__item blue'>
              Informes diarios, documentación, actas de entrega y fotografías de vehículos
            </div>
            <div className='grid__item'>Call Center 24 h, 7 días a la semana</div>
            <div className='grid__item blue'>Con traslados de larga distancia</div>
            <div className='grid__item blue'>Supervisión en tiempo real de los servicios</div>
            <div className='grid__item'>Recogidas o entregas completamente personalizadas</div>
            <div className='grid__item blue'>Coordinación con el cliente final</div>
          </div>
        </div>
      </div>
      <div className='flex h-center fourth-section' id='us'>
        <div className='flex end general-wrapper'>
          <div className='data'>
            <div className='subtitle'>Nosotros</div>
            <h2>Especialistas en gestión de servicios y software de movilidad</h2>
            <p>
              Hemos unificado lo mejor del mundo de la asistencia con lo mejor del mundo de la tecnología. De esta unión
              nace car2door, la plataforma SAAS de gestión de recogida y entrega de vehículos.
            </p>
            <p>
              Invertimos constantemente en I+D+i, lo que repercute en nuestra eficiencia interna y en unos óptimos
              canales de comunicación con los clientes.
            </p>
            <div className='values'>Valores</div>
            <div className='flex cols-2 between'>
              <div>
                <div className='values__title'>Calidad</div>
                <div>Somos la imagen de nuestros clientes. Nada debe fallar.</div>
              </div>
              <div>
                <div className='values__title'>Experiencia</div>
                <div>+ de 20 años en tecnología y en asistencia.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex h-center footer' id='footer'>
        <div className='general-wrapper'>
          <div className='flex cols'>
            <div className='col-1-4'>
              <div className='logo'>
                <img src='/assets/logo.svg' />
              </div>
            </div>
            <div className='col-2-4'>
              <div className='footer__text'>Tu servicio de movilidad, adaptado a tus necesidades.</div>
            </div>
            <div className='col-1-4 footer__contact'>
              <div className='contact__title'>Contacta con nosotros</div>
              <div>
                <a href='mailto:hola@car2door.es'>hola@car2door.es</a>
              </div>
              <div>
                <a href='tel:+34913179274'>(+34) 91 317 92 74</a>
              </div>
              <div>C/ San Francisco de Asís 5</div>
              <div>Pozuelo de Alarcón</div>
            </div>
          </div>
          <div className='flex end footer__lower'>
            <div>
              <a href=''>Términos y condiciones</a>
            </div>
            <div>
              <a href=''>Cookies</a>
            </div>
            <div>
              <a href=''>©2020 Car2door Inc.</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
